import React from 'react';
import './App.scss';
import './css/styles.scss';
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Policy from './Policy';

function App() {
  const links = [
    { name: "P01-Introduction", version: "03", effective: '01JUL2021', retired: '' },
    { name: "P02-Quality management system", version: "03", effective: '22JUN2021', retired: '' },
    { name: "P03-Yearly review", version: "03", effective: '01JUL2021', retired: '' },
    { name: "P04-Risk management", version: "02", effective: '01JUL2021', retired: '' },
    { name: "P05-Suppliers", version: "03", effective: '01JUL2021', retired: '' },
    { name: "P06-Data management and retention", version: "03", effective: '22JUN2021', retired: '' },
    { name: "P07-Systems access", version: "03", effective: '01JUL2021', retired: '' },
    { name: "P08-Auditing", version: "02", effective: '01DEC2019', retired: '' },
    { name: "P09-Configuration management", version: "01", effective: '01DEC2019', retired: '' },
    { name: "P10-Facility access", version: "01", effective: '01DEC2019', retired: '' },
    { name: "P11-Incidence response", version: "02", effective: '01JUL2021', retired: '' },
    { name: "P12-Breach", version: "02", effective: '01JUL2021', retired: '' },
    { name: "P13-Disaster recovery", version: "01", effective: '01DEC2019', retired: '' },
    { name: "P14-Disposable media", version: "02", effective: '01JUL2021', retired: '' },
    { name: "P15-Employees", version: "02", effective: '01DEC2019', retired: '' },
    { name: "P16-Approved tools", version: "01", effective: '01DEC2019', retired: '' },
    { name: "P17-3rd party", version: "01", effective: '01DEC2019', retired: '' },
    { name: "P18-Software development lifecycle", version: "01", effective: '01DEC2019', retired: '' },
    { name: "P19-Programming", version: "02", effective: '01JUL2021', retired: '' },
    { name: "P20-Statistics", version: "01", effective: '01DEC2019', retired: '' },
    { name: "P21-GDPR", version: "01", effective: '01JUL2021', retired: '' }
  ]

  function replaceAll(str, find, replace) {
    return str.replace(new RegExp(find, 'g'), replace);
  }

  const toShortFormat = (date) => {

    var month_names = ["Jan", "Feb", "Mar",
      "Apr", "May", "Jun",
      "Jul", "Aug", "Sep",
      "Oct", "Nov", "Dec"];

    var day = date.getDate();
    var month_index = date.getMonth();
    var year = date.getFullYear();

    return "" + day + "-" + month_names[month_index] + "-" + year;
  }

  // and it can represent itself in the custom format defined above.



  const printSection = (name, version, effective, retired) => {
    var today = toShortFormat(new Date());
    console.log(today);

    var printContents = document.getElementById(name).innerHTML;
    var mywindow = window.open('', 'PRINT', '');
    mywindow.document.write('<html><head>');
    mywindow.document.write('<title>' + name + '</title>');
    mywindow.document.write('<link rel="stylesheet" type="text/css" href="print.css"/>');
    mywindow.document.write('</head><body>');
    mywindow.document.write(printContents);
    if (retired === '') {
      mywindow.document.write('<footer>Version: ' + version + ', Effective date: ' + effective + ', Printed on: ' + today + '</footer>');
    }
    else {
      mywindow.document.write('<footer>Version: ' + version + ', Effective date: ' + effective + ' , Printed on: ' + today + ', Retired date: ' + retired + '</footer>');
    }
    mywindow.document.write('</body>');
    mywindow.document.write('</html>');
    mywindow.document.close();
    mywindow.focus()
    setTimeout(function () { mywindow.print(); mywindow.close(); }, 500);
    return true;
  }

  return (
    <div className="App">
      <div className="fixed-bar"></div>
      <header className="sub-header">
        <div className="grid">
          <h1 className="site-title">Quality Management System</h1>
        </div>
      </header>
      <aside className="menu">
        {
          links.map((link, i) => {
            let link_id = replaceAll(link.name, " ", "_");
            let version = link.version;
            let effective = link.effective;
            let retired = link.retired;
            return (
              <div key={`ab-${i}`} style={{ padding: "6px" }}>
                <a key={`a-${i}`} href={`#${link_id}`}>{`${link.name}`}</a>
                <button className="print-btn" key={`b-${i}`} onClick={() => printSection(`${link_id}`, `${version}`, `${effective}`, `${retired}`)}>
                  <FontAwesomeIcon icon={faPrint} />
                </button>
              </div>
            )
          })
        }
      </aside>
      <div className="cf grid">
        <Policy />
      </div>
    </div>
  );
}

export default App;
