import React, { Component } from 'react'
import ReactMarkdown from 'react-markdown'
import p01 from './policies/P01-introduction.md'
import p02 from './policies/P02-quality_management_system.md'
import p03 from './policies/P03-yearly_review.md'
import p04 from './policies/P04-risk_management.md'
import p05 from './policies/P05-Suppliers.md'
import p06 from './policies/P06-data_management_and_retention.md'
import p07 from './policies/P07-systems_access.md'
import p08 from './policies/P08-Auditing.md'
import p09 from './policies/P09-configuration_management.md'
import p10 from './policies/P10-facility_access.md'
import p11 from './policies/P11-incident_response.md'
import p12 from './policies/P12-breach.md'
import p13 from './policies/P13-disaster_recovery.md'
import p14 from './policies/P14-disposable_media.md'
import p15 from './policies/P15-employees.md'
import p16 from './policies/P16-approved_tools.md'
import p17 from './policies/P17-3rd_party.md'
import p18 from './policies/P18-software_development_lifecycle.md'
import p19 from './policies/P19-programming.md'
import p20 from './policies/P20-statistics.md'
import p21 from './policies/P21-GDPR.md'


class Policy extends Component {
  constructor(props) {
    super(props)
    this.state = {
      p01: null,
      p02: null,
      p03: null,
      p04: null,
      p05: null,
      p06: null,
      p07: null,
      p08: null,
      p09: null,
      p10: null,
      p11: null,
      p12: null,
      p13: null,
      p14: null,
      p15: null,
      p16: null,
      p17: null,
      p18: null,
      p19: null,
      p20: null,
      p21: null,
    }
  }
  componentDidMount() {
    fetch(p01).then(res => res.text()).then(text => this.setState({ p01: text }));
    fetch(p02).then(res => res.text()).then(text => this.setState({ p02: text }));
    fetch(p03).then(res => res.text()).then(text => this.setState({ p03: text }));
    fetch(p04).then(res => res.text()).then(text => this.setState({ p04: text }));
    fetch(p05).then(res => res.text()).then(text => this.setState({ p05: text }));
    fetch(p06).then(res => res.text()).then(text => this.setState({ p06: text }));
    fetch(p07).then(res => res.text()).then(text => this.setState({ p07: text }));
    fetch(p08).then(res => res.text()).then(text => this.setState({ p08: text }));
    fetch(p09).then(res => res.text()).then(text => this.setState({ p09: text }));
    fetch(p10).then(res => res.text()).then(text => this.setState({ p10: text }));
    fetch(p11).then(res => res.text()).then(text => this.setState({ p11: text }));
    fetch(p12).then(res => res.text()).then(text => this.setState({ p12: text }));
    fetch(p13).then(res => res.text()).then(text => this.setState({ p13: text }));
    fetch(p14).then(res => res.text()).then(text => this.setState({ p14: text }));
    fetch(p15).then(res => res.text()).then(text => this.setState({ p15: text }));
    fetch(p16).then(res => res.text()).then(text => this.setState({ p16: text }));
    fetch(p17).then(res => res.text()).then(text => this.setState({ p17: text }));
    fetch(p18).then(res => res.text()).then(text => this.setState({ p18: text }));
    fetch(p19).then(res => res.text()).then(text => this.setState({ p19: text }));
    fetch(p20).then(res => res.text()).then(text => this.setState({ p20: text }));
    fetch(p21).then(res => res.text()).then(text => this.setState({ p21: text }));
  }


  render() {
    let { p01, p02, p03, p04, p05, p06, p07, p08, p09, p10, p11, p12, p13, p14, p15, p16, p17, p18, p19, p20, p21 } = this.state;
    return (
      <div className="content">
        <section id={"P01-Introduction"}> {p01 && <ReactMarkdown source={p01} />} </section>
        <section id={"P02-Quality_management_system"}> {p02 && <ReactMarkdown source={p02} />} </section>
        <section id={"P03-Yearly_review"}> {p03 && <ReactMarkdown source={p03} />} </section>
        <section id={"P04-Risk_management"}> {p04 && <ReactMarkdown source={p04} />} </section>
        <section id={"P05-Suppliers"}> {p05 && <ReactMarkdown source={p05} />} </section>
        <section id={"P06-Data_management_and_retention"}> {p06 && <ReactMarkdown source={p06} />} </section>
        <section id={"P07-Systems_access"}> {p07 && <ReactMarkdown source={p07} />} </section>
        <section id={"P08-Auditing"}> {p08 && <ReactMarkdown source={p08} />} </section>
        <section id={"P09-Configuration_management"}> {p09 && <ReactMarkdown source={p09} />} </section>
        <section id={"P10-Facility_access"}> {p10 && <ReactMarkdown source={p10} />} </section>
        <section id={"P11-Incidence_response"}> {p11 && <ReactMarkdown source={p11} />} </section>
        <section id={"P12-Breach"}> {p12 && <ReactMarkdown source={p12} />} </section>
        <section id={"P13-Disaster_recovery"}> {p13 && <ReactMarkdown source={p13} />} </section>
        <section id={"P14-Disposable_media"}> {p14 && <ReactMarkdown source={p14} />} </section>
        <section id={"P15-Employees"}> {p15 && <ReactMarkdown source={p15} />} </section>
        <section id={"P16-Approved_tools"}> {p16 && <ReactMarkdown source={p16} />} </section>
        <section id={"P17-3rd_party"}> {p17 && <ReactMarkdown source={p17} />} </section>
        <section id={"P18-Software_development_lifecycle"}> {p18 && <ReactMarkdown source={p18} />} </section>
        <section id={"P19-Programming"}> {p19 && <ReactMarkdown source={p19} />} </section>
        <section id={"P20-Statistics"}> {p20 && <ReactMarkdown source={p20} />} </section>
        <section id={"P21-GDPR"}> {p21 && <ReactMarkdown source={p21} />} </section>
      </div>
    )
  }
}

export default Policy;

